import { LoadingCard, OverviewPage, ProductStatusSection } from '@shopify/channels-ui';
import { Button } from '@shopify/polaris';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { useAuthenticatedFetch } from '../hooks/useAuthenticatedFetch';

function useConnectProducts() {
  const fetch = useAuthenticatedFetch();
  const fetcher = (url) => fetch(url).then((res) => res.json());
  const { data, error, mutate } = useSWR('/connect/overview', fetcher);

  const products = data?.products;
  const publicationId = data?.publicationId;
  const shop = data?.shop;

  return {
    products,
    publicationId,
    shop,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
}

function useSalesData() {
  const fetch = useAuthenticatedFetch();
  const fetcher = (url) => fetch(url).then((res) => res.json());
  const { data } = useSWR('/sales', fetcher);

  console.log(data);
}

export default function MyOverviewPage() {
  const navigate = useNavigate();
  const { products, publicationId, shop, isLoading } = useConnectProducts();
  useSalesData();

  if (isLoading) {
    return <LoadingCard lines={3} basic wrapped />;
  }

  return (
    <OverviewPage title="CreatorCart channel overview" footer={<Button onClick={() => navigate('/connection')}>Manage account</Button>}>
      <OverviewPage.Section title="Product status on CreatorCart">
        <ProductStatusSection
          summary={`${products} products are available to the CreatorCart channel`}
          manageAction={{
            content: 'Manage availability',
            external: true,
            url: `https://${shop}/admin/bulk?resource_name=Product&edit=publications.${publicationId}.published_at`,
          }}
          productStatuses={[
            {
              badge: {
                status: products === 0 ? 'attention' : 'success',
                children: products === 0 ? 'Missing' : 'Approved',
              },
              label: `${products} products`,
            },
          ]}
        />
      </OverviewPage.Section>
      {/* <OverviewPage.Section title="CreatorCarts featuring your products">
        <FeatureListCard
          features={[
            {
              title: '@stagesofgrey',
              description: (
                <div>
                  <ButtonGroup>
                    <Button size="slim" url="https://twitter.com" external>
                      Twitter
                    </Button>
                    <Button size="slim">Instagram</Button>
                    <Button size="slim">Tiktok</Button>
                  </ButtonGroup>
                </div>
              ),
              // badge: {
              //   status: 'success',
              //   children: 'Active',
              // },
              action: {
                content: 'View shop',
              },
            },
          ]}
        />
      </OverviewPage.Section> */}
    </OverviewPage>
  );
}
