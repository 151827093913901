import { Button, FormLayout, TextField, Toast, Form } from '@shopify/polaris';
import { useCallback, useState } from 'react';
import { useAuthCheck } from './hooks/useAuthCheck';

export function LoginForm() {
  const { mutate } = useAuthCheck();

  const [email, setEmail] = useState('');
  const handleEmailChange = useCallback((value) => setEmail(value), []);

  const [password, setPassword] = useState('');
  const handlePasswordChange = useCallback((value) => setPassword(value), []);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    setLoading(true);
    const resp = await fetch('/api/auth/dashboard/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });

    await mutate();

    if (!resp.ok) {
      setError(true);
    }

    setLoading(false);
  };

  return (
    <Form onSubmit={onSubmit}>
      <FormLayout>
        <TextField value={email} onChange={handleEmailChange} label="Email" type="email" autoComplete="email" />
        <TextField value={password} onChange={handlePasswordChange} label="Password" type="password" autoComplete="password" />

        <Button submit primary loading={loading}>
          Login
        </Button>

        {error && <Toast content="Wrong username/password" error onDismiss={() => setError(false)} />}
      </FormLayout>
    </Form>
  );
}
