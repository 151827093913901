import useSWR from 'swr';
import { useAuthenticatedFetch } from './useAuthenticatedFetch';

const fetcher = (url) => fetch(url).then((res) => res.json());

export function useAuthCheck() {
  const { data, error, mutate } = useSWR('/api/auth/dashboard/check', fetcher);

  return {
    user: data?.user,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
}
