import { FeatureCard, IntroductionPage, LoadingCard } from '@shopify/channels-ui';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStorefrontCheck } from '../hooks/useStorefrontCheck';

export default function CreatorCartIntroductionPage() {
  const { connected: accountConnected, isLoading } = useStorefrontCheck();
  const navigate = useNavigate();

  const handleAction = () => {
    console.log('onboarding');
    navigate('/onboarding');
  };

  useEffect(
    function () {
      if (accountConnected) {
        navigate('/overview');
      }
    },
    [accountConnected]
  );

  if (isLoading) {
    return <LoadingCard lines={3} basic wrapped />;
  }

  return (
    <IntroductionPage title="Get started with the CreatorCart channel">
      <FeatureCard
        title="Have creators sell your products with CreatorCart"
        feature="CreatorCart SMS Campaign"
        portrait
        description="Let's get you ready for SMS sales via creators!"
        badgeText="Free"
        primaryAction={{
          content: 'Start setup',
          onAction: handleAction,
        }}
      >
        <img width="100%" src="https://burst.shopifycdn.com/photos/laptop-from-above.jpg?width=750&format=pjpg" />
      </FeatureCard>
    </IntroductionPage>
  );
}
