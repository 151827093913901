import { Button, FormLayout, TextField, Toast, Form } from '@shopify/polaris';
import { useCallback, useState } from 'react';
import { useAuthCheck } from './hooks/useAuthCheck';

export function RegisterForm() {
  const { mutate } = useAuthCheck();

  const [name, setName] = useState('');
  const handleNameChange = useCallback((value) => setName(value), []);

  const [email, setEmail] = useState('');
  const handleEmailChange = useCallback((value) => setEmail(value), []);

  const [password, setPassword] = useState('');
  const handlePasswordChange = useCallback((value) => setPassword(value), []);

  const [password2, setPassword2] = useState('');
  const handlePassword2Change = useCallback((value) => setPassword2(value), []);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (password !== password2) {
      setError(`Passwords do not match`);
      return;
    }

    setLoading(true);
    const resp = await fetch('/api/auth/dashboard/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, brand: null, email, password }),
    });

    await mutate();
    setLoading(false);
    const response = await resp.json();

    if (!resp.ok) {
      setError('User already exists');
    } else if (response.success === false) {
      setError('Bad input');
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <FormLayout>
        <TextField value={name} onChange={handleNameChange} label="Name" type="text" autoComplete="name" />
        <TextField value={email} onChange={handleEmailChange} label="Email" type="email" autoComplete="email" />
        <TextField value={password} onChange={handlePasswordChange} label="Password" type="password" autoComplete="password" />
        <TextField
          value={password2}
          onChange={handlePassword2Change}
          label="Confirm Password"
          type="password"
          autoComplete="confirm-password"
        />

        <Button submit primary loading={loading}>
          Register
        </Button>
      </FormLayout>
      {error !== '' && <Toast content={error} error onDismiss={() => setError('')} />}
    </Form>
  );
}
