import { OnboardingCard, OnboardingPage } from '@shopify/channels-ui';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthCheck } from '../hooks/useAuthCheck';
import { useAuthenticatedFetch } from '../hooks/useAuthenticatedFetch';
import { LoginForm } from '../LoginForm';
import { RegisterForm } from '../RegisterForm';

export default function ConnectPage() {
  const { user, mutate } = useAuthCheck();
  const authFetch = useAuthenticatedFetch();

  const [showLogin, setShowLogin] = useState(true);
  const [completed, setCompleted] = useState(false);

  const navigate = useNavigate();

  const [connectLoading, setConnectLoading] = useState(false);

  const toggleForm = () => {
    setShowLogin((state) => !state);
  };

  const handleBreadcrumbAction = () => {
    navigate('/onboarding');
  };

  const handleFinishSetup = async () => {
    setConnectLoading(true);

    const { url } = await authFetch('/connect/begin').then(res => res.json());
    const popup = window.open(url, '', 'width=600,height=600');
    const timer = setInterval(function () {
      if (popup.closed) {
        clearInterval(timer);
        setConnectLoading(false);
        navigate('/onboarding');
      }
    }, 1000);
  };

  const state = (function () {
    if (completed) {
      return 'disabled';
    }

    if (user) return 'confirm';

    if (showLogin) return 'login';
    return 'register';
  })();

  return (
    <OnboardingPage
      title="Connect Shopify with CreatorCart"
      breadcrumb={{
        onAction: handleBreadcrumbAction,
      }}
      action={{
        content: 'Connect account',
        disabled: state !== 'confirm',
        loading: connectLoading,
        onAction: handleFinishSetup,
      }}
    >
      <OnboardingCard
        id="login"
        title="Login with existing CreatorCart account"
        state={state === 'login' ? 'active' : 'disabled'}
        sectioned
        action={{
          content: `No account? Signup here`,
          onAction() {
            toggleForm();
          },
        }}
      >
        <LoginForm />
      </OnboardingCard>

      <OnboardingCard
        id="signup"
        title="Create a new CreatorCart account"
        state={state === 'register' ? 'active' : 'disabled'}
        sectioned
        action={{
          content: `Already have an account? Login here`,
          onAction() {
            toggleForm();
          },
        }}
      >
        <RegisterForm />
      </OnboardingCard>

      <OnboardingCard
        id="confirm"
        title="Link your current account"
        state={state === 'confirm' ? 'active' : 'disabled'}
        sectioned
        action={{
          content: `Wrong account? Login here`,
          async onAction() {
            const resp = await fetch('/api/auth/dashboard/logout', {
              mode: 'cors',
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({}),
            });
            await mutate();
            toggleForm();
          },
        }}
      >
        <p>{user?.email}</p>
      </OnboardingCard>
    </OnboardingPage>
  );
}
