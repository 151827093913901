import '@shopify/polaris/build/esm/styles.css';
import '@shopify/channels-ui/build/esm/styles.css';
import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://acf7ffd5b6cc4c6287fdb15d82b4a3cb@o1290035.ingest.sentry.io/6753251',
  environment: window.location.hostname.includes('.local.') ? 'dev' : 'prd',
  release: import.meta.env.VITE_APP_RELEASE,
  integrations: [],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,
});

ReactDOM.render(<App />, document.getElementById('app'));
