import { createBrowserHistory } from 'history';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { ExtendedAppProvider } from './components/providers';
import { AppRoutes } from './components/providers/AppRoutes';

const history = createBrowserHistory({ window: window.frames['app-iframe'] });

export default function App() {
  return (
    <HistoryRouter history={history}>
      <ExtendedAppProvider>
        <AppRoutes />
      </ExtendedAppProvider>
    </HistoryRouter>
  );
}
