import { AccountConnection, LoadingCard, OnboardingCard, OnboardingCardState, OnboardingPage } from '@shopify/channels-ui';
import { useNavigate } from 'react-router-dom';
import { useAuthenticatedFetch } from '../hooks/useAuthenticatedFetch';
import { useStorefrontCheck } from '../hooks/useStorefrontCheck';

export default function ConnectionPage() {
  const fetch = useAuthenticatedFetch();
  const navigate = useNavigate();
  const { connected: accountConnected, user, terms, isLoading, mutate } = useStorefrontCheck();

  const handleBreadcrumbAction = () => {
    navigate('/overview');
  };

  const handleAccountConnect = () => {
    navigate('/connect');
  };

  const handleAccountDisconnect = async () => {
    await fetch('/connect/disconnect', {
      method: 'post',
    });

    await mutate();
  };

  const handleFinishSetup = () => {
    navigate('/overview');
  };

  let termState: OnboardingCardState = 'active';
  let accountState: OnboardingCardState = 'disabled';

  if (terms) {
    termState = 'completed';
    accountState = 'active';
  }

  if (accountConnected) {
    accountState = 'completed';
  }

  // set AccountConnection props based on whether an account has been connected
  const accountConnectionProps = accountConnected
    ? {
        content: user?.email, // an identifier for the account on your platform
        // avatar: 'https://burst.shopifycdn.com/photos/fashion-model-in-fur.jpg?width=373', // thumbnail representation of the account on your platform (optional)
        action: {
          content: 'Disconnect',
          onAction: handleAccountDisconnect,
        },
        connected: true,
      }
    : {
        content: 'No account connected',
        action: {
          content: 'Connect account',
          onAction: handleAccountConnect,
        },
        connected: false,
      };

  if (isLoading) {
    return <LoadingCard lines={3} basic wrapped />;
  }

  return (
    <OnboardingPage
      title="Manage your account"
      breadcrumb={{
        onAction: handleBreadcrumbAction,
      }}
      action={{
        content: 'Go Back',
        onAction: handleFinishSetup,
      }}
    >
      <div></div>
      <OnboardingCard
        id="account-connection"
        title="Connect your CreatorCart account"
        state="active"
        footer="This account is needed to sync your products to CreatorCart"
      >
        <AccountConnection {...accountConnectionProps} />
      </OnboardingCard>
    </OnboardingPage>
  );
}
