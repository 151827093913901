import { Frame } from '@shopify/polaris';
import { useRoutePropagation } from '@shopify/app-bridge-react';
import { Route, Routes, useLocation } from 'react-router-dom';
import IntroductionPage from '../pages/IntroductionPage';
import ConnectPage from '../pages/ConnectPage';
import OnboardingPage from '../pages/OnboardingPage';
import OverviewPage from '../pages/OverviewPage';
import ConnectionPage from '../pages/ConnectionPage';

export function AppRoutes() {
  const location = useLocation();
  useRoutePropagation(location);

  return (
    <Frame>
      <Routes location={location}>
        <Route path="/" element={<IntroductionPage />} />
        <Route path="login" element={<IntroductionPage />} />
        <Route path="onboarding" element={<OnboardingPage />} />
        <Route path="connect" element={<ConnectPage />} />
        <Route path="overview" element={<OverviewPage />} />
        <Route path="connection" element={<ConnectionPage />} />
      </Routes>
    </Frame>
  );
}
