import useSWR from 'swr';
import { useAuthenticatedFetch } from './useAuthenticatedFetch';

export function useStorefrontCheck() {
  const fetch = useAuthenticatedFetch();
  const fetcher = (url) => fetch(url).then((res) => res.json());
  const { data, error, mutate } = useSWR('/connect/status', fetcher);

  const user: Record<string, unknown> | null = data?.user;
  const connected: boolean | null = data?.connected;
  const terms: boolean | null = data?.terms;

  return {
    user,
    connected,
    terms,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
}
