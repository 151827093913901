/**
 * This includes AppBridgeProvider and PolarisProvider
 * https://github.com/Shopify/channels-ui-docs#usage
 */
import { AppConfigV2 } from '@shopify/app-bridge';
import { ExtendedAppProvider as Provider } from '@shopify/channels-ui';
import channelsUiTranslations from '@shopify/channels-ui/locales/en.json';
import polarisTranslations from '@shopify/polaris/locales/en.json';
import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Link from '../Link';

const API_KEY = '57ce3e8329bee622b81186b3a563f218';

const HostContext = React.createContext('');

export const useHostContext = () => React.useContext(HostContext);

export function ExtendedAppProvider({ children }) {
  // The host may be present initially, but later removed by navigation.
  // By caching this in state, we ensure that the host is never lost.
  // During the lifecycle of an app, these values should never be updated anyway.
  // Using state in this way is preferable to useMemo.
  // See: https://stackoverflow.com/questions/60482318/version-of-usememo-for-caching-a-value-that-will-never-change
  const [appBridgeConfig] = React.useState<AppConfigV2>(() => {
    // ?host=`${btoa('creatorcart-test15.myshopify.com/admin')}`
    // ?host=Y3JlYXRvcmNhcnQtdGVzdDE1Lm15c2hvcGlmeS5jb20vYWRtaW4=
    const host = new URLSearchParams(window.location.search).get('host');

    return {
      host,
      apiKey: API_KEY,
      forceRedirect: true,
    };
  });

  const navigate = useNavigate();
  const location = useLocation();
  const history = useMemo(() => ({ replace: (path) => navigate(path, { replace: true }) }), [navigate]);

  const router = useMemo(
    () => ({
      location,
      history,
    }),
    [location, navigate]
  );

  return (
    <HostContext.Provider value={appBridgeConfig.host}>
      <Provider
        config={appBridgeConfig}
        polaris={{ i18n: polarisTranslations, linkComponent: Link }}
        i18n={channelsUiTranslations}
        router={router}
      >
        {children}
      </Provider>
    </HostContext.Provider>
  );
}
