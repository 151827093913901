import { AccountConnection, LoadingCard, OnboardingCard, OnboardingCardState, OnboardingPage } from '@shopify/channels-ui';
import { Button, Link, Stack } from '@shopify/polaris';
import { useNavigate } from 'react-router-dom';
import { useAuthenticatedFetch } from '../hooks/useAuthenticatedFetch';
import { useStorefrontCheck } from '../hooks/useStorefrontCheck';

export default function CreatorCartOnboardingPage() {
  const fetch = useAuthenticatedFetch();
  const navigate = useNavigate();
  const { connected: accountConnected, user, terms, isLoading, mutate } = useStorefrontCheck();

  const handleBreadcrumbAction = () => {
    navigate('/login');
  };

  const handleAccountConnect = () => {
    navigate('/connect');
  };

  const handleAccountDisconnect = async () => {
    await fetch('/connect/disconnect', {
      method: 'post',
    });

    await mutate();
  };

  const handleTermsAccept = async () => {
    await fetch('/connect/terms', {
      method: 'post',
    });

    await mutate();
  };

  const handleFinishSetup = () => {
    navigate('/overview');
  };

  let termState: OnboardingCardState = 'active';
  let accountState: OnboardingCardState = 'disabled';

  if (terms) {
    termState = 'completed';
    accountState = 'active';
  }

  if (accountConnected) {
    accountState = 'completed';
  }

  // set AccountConnection props based on whether an account has been connected
  const accountConnectionProps = accountConnected
    ? {
        content: user?.email, // an identifier for the account on your platform
        // avatar: 'https://burst.shopifycdn.com/photos/fashion-model-in-fur.jpg?width=373', // thumbnail representation of the account on your platform (optional)
        action: {
          content: 'Disconnect',
          onAction: handleAccountDisconnect,
        },
        connected: true,
      }
    : {
        content: 'No account connected',
        action: {
          content: 'Connect account',
          onAction: handleAccountConnect,
        },
        connected: false,
      };

  if (isLoading) {
    return <LoadingCard lines={3} basic wrapped />;
  }

  return (
    <OnboardingPage
      title="Set up CreatorCart Shopping"
      breadcrumb={{
        onAction: handleBreadcrumbAction,
      }}
      action={{
        content: 'Finish setup',
        disabled: !accountConnected,
        onAction: handleFinishSetup,
      }}
    >
      <OnboardingCard id="terms" title="Accept the terms of service" state={termState} sectioned>
        <Stack vertical>
          <p>
            In order to complete set up, you need to read and agree to <Link>CreatorCart Merchant Terms of Service</Link> and{' '}
            <Link>CreatorCart Shopping Terms of Service</Link>
          </p>
          <Stack distribution="trailing">
            <Button primary onClick={handleTermsAccept} disabled={terms}>
              Accept
            </Button>
          </Stack>
        </Stack>
      </OnboardingCard>
      <OnboardingCard
        id="account-connection"
        title="Connect your CreatorCart account"
        state={accountState}
        footer="This account is needed to sync your products to CreatorCart"
      >
        <AccountConnection {...accountConnectionProps} />
      </OnboardingCard>
    </OnboardingPage>
  );
}
